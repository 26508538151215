export const siteData = {
  "name": "VILAMIN",
  "address": "8 RUE DES FRERES VOISIN,",
  "postCode": "72000",
  "city": "le",
  "phone": "0807956189",
  "url": "https://vilamin.store",
  "urlSub": "vilamin.store",
  "price": "price_1Q55FXLwhTs22ZtEjHvrg0d7",
  "pk": "pk_live_51Q55B3LwhTs22ZtE2gUjGnGP5tWNgg9WCkbmDmxKMOfPcFOXCmbVENn0nNgpYE1EzDkADDDDnSG1nsovp8dItqvJ009Q9h6vId",
  "sk": "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"
};